.navbar {
    position: fixed;
    display: flex;
    width: 100%;
    height: 52px;
    background: #000;
    opacity: 0.7;
    z-index: 1;
    backdrop-filter: blur(8px);
  }
  
  .links {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }
  
  .navbar a {
    color: white;
    text-decoration: none;
    margin: 20px;
    font-size: 100%;
  }
  
  .toggleButton {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  .toggleButton svg {
    font-size: 50px;
  }
  .toggleButton button {
    margin-right: 20px;
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
  }
  
  #open {
    height: 100vh;
  }
  
  #open .links {
    flex-direction: column;
    justify-content: flex;
    text-align: center;
    margin-top: 10px;
    font-size: 2rem;
  }
  
  #open a {
    width: 100%;
    text-align: center;
  }
  
  @media only screen and (max-width: 768px) {
    .navbar {
      display: block;
      opacity: 0.7;
      justify-content: center;
      z-index: 1000;
      height: 4rem;
    }

    .toggleButton {
      display: flex;
      margin-top: 1rem;
      height:40px;
    }
    
    #open {
      opacity: 0.9;
    }

    #close a {
      display: none;
    }
  }
  
  @media only screen and (min-width: 768px) {

    .toggleButton {
      display: none;
    }
  
    .hiddenLinks {
      display: none;
    }
  
    #open {
      height: 100px;
    }
  
    #open .links {
      flex-direction: row;
    }
  }