.home {
    width: 100%;
    min-height: 100vh;
    align-items: center;
    font-family: "Arial", sans-serif;
    justify-content: center;
  }

  .container {
    display: flex;
    justify-content: center;
  }

  .icon {
    width: 100%;
  }

  .content {
    max-width: 1140px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .about {
    width: 100%;
    height: 500px;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-image: url('../assets/marble.jpg');
    background-color: rgb(0, 0, 0);
    background-position: 0% 100%;
    color: #f0f0f0;
  }

  .about::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 500px; 
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #131313);
}

  
  .about h2 {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 80px;
    color: white;
    height: 50px;
  }
  
  .about .prompt {
    width: 40%;
    font-size: 30px;
    z-index: 0;
  }

  .prompt p {
    /* white-space: nowrap; */
    text-align: center;
  }

  .prompt svg {
    font-size: 60px;
    margin: 15px;
    color: white;
  }

  #title {
    font-family: 'Gill Sans';
    line-height: 1;
    text-align: center;
    text-transform: capitalize;
    font-size: 6rem;
    font-weight: 400;
    z-index: 0;
    margin-bottom: 1rem;
  }

  .title {
    font-family: 'Gill Sans';
    line-height: 1;
    margin-top: 0;
    margin-bottom: 0;
    text-transform: capitalize;
    font-size: 4rem;
    font-weight: 400;
    z-index: 0;
  }

  h1 {
    font-family: 'Gill Sans';
    color: white;
    font-weight: 400;
    font-size: 3rem;
  }

  h2 {
    margin: 10px;
    margin-left: 0px;
    padding-top: 20px;
    line-height: 1;
    text-align: left;
    font-size: 2rem;
    color: white;
  }

  /* about me section */
  .more {
    width: 100%;
    flex-wrap: wrap;
    font-size: 1.5rem;
    text-align:left;
    align-items: flex-start;
    justify-content: space-between;
  }

  .more p {
    line-height: 1.5;
    color: white;
    margin: 0;
    margin-bottom: 50px;
  }

  #right img {
    float: right;
    margin-left: 20px;
  }

  .more img {
    max-width: 35%;
    max-height: 35%;
    float: left;
    height: auto;
    border-radius: 2rem;
    margin-right: 20px;
    margin-bottom: 5px;
  }
  
  .skills {
    font-size: 35px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: left;
    color: white;
  }

  ol {
    text-align: left;
  }

  .skills .list {
    justify-content: center;
    align-items: center;
    line-height: 1;
    margin: 0;
    padding:0;
    list-style: none;
    width: 100%;
  }
  
  .list span {
    font-size: 1.5rem;
    text-align: left;
  }
  
  @media only screen and (max-width: 650px) {
    .about {
      height: 400px;
    }

    .about h2 {
      font-size: 40px;
    }

    .icon {
      width:100%;
      display:flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    #title {
      font-size: 5rem;
    }

    .about .prompt {
      margin-top: 10px;
      font-size: 20px;
      width: 80%;
    }
  
    .skills {
      text-align: center;
    }
    .list {
      padding: 0;
    }
  
    .skills h2 {
      font-size: 30px;
    }
  }