.footer {
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
    margin-top: 100px;
    background-position: bottom right;
    position:50px;
  }
  
  .socialMedia {
    color: white;
    margin: 0px;
    font-size: 70px;
    cursor: pointer;
  }
  
  .footer p {
    color: white;
  }