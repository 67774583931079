.contact {
    margin-top:5rem;
    margin-left:5%;
    margin-right:5%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.email{
    color: white;
    font-size: 1.5rem;
}

.contact-form {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-icon {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}