.App {
  text-align: center;
  background-color:#131313;
  font-family: 'Helvetica Neue' ;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

body{
  padding:0;
  margin:0;
  background-color: #131313;
}

.navbar {
  z-index: 100;
}

.pages::before{
  content: "";
  position: fixed;
  bottom: 96%; /* Adjust this value to match the height of your navbar */
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(35px);
}

.pages {
  display:flex;
  justify-content: center;
}

.main {
  min-height: 100vh;
}

.footer {
  margin-top: auto;
}

@media only screen and (max-width: 650px) {
  .pages::before{
    backdrop-filter: none; 
  }
}