.experience {
    padding-top: 5rem;
    min-height:100vh;
    align-items: center;
    justify-content: top;
    display:flex;
    flex-direction: column;
    max-width: 1100px;
    margin-left: 20px;
    margin-right:20px;
}

#expitemtitle {
    margin-top: 0;
    padding-top: 0;
}

.alignleft {
    width:100%;
    text-emphasis: left;
    align-items: left;
}

.exptitle {
    font-family: 'Gill Sans';
    font-size: 3rem;
    padding-top: 3%;
    font-weight: 400;
}

.list {
    list-style: none;
    width: 100%;
    margin: 0;
    padding: 0;
}

.sublist {
    padding: 0;
    margin: 0;
}

#itemexp {
    color: white;
    width: 100%;
    clear:left;
    padding-top: 4%;
    display: flex;
    flex-direction: row;
}


.subitem {
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 1.3rem;
}

#expimg {
    max-width: 10%;
    height: 10%;
    border-radius: 1rem;
    float: left;
    margin-right: 20px;
}

#expspan {
    float: right;
    font-size: 1rem;
}

#iteminfo {
    width:100%;
}

@media only screen and (max-width: 768px) {
    body {
        transform: scale(0.5);
        transform-origin: top left;
        width: calc(100% / 0.5);  
      }
    
    .exptitle {
        font-size: 2rem;
    }
  }

