.project {
    width: 100%;
    min-height: 81vh;
    display: flex;
    justify-content: top;
    align-items: center;
    flex-direction: column;
    margin-top: 5%;
  }
  
  .icon {
    font-size: 5rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .project h1 {
    color: white;
    font-weight: 400;
    font-size: 5rem;
    margin-top: 5%;
  }
  .project img {
    max-width: 90%;
    height: auto;
    border-radius: 10px;
  }

  .projectdesc {
    padding-top: 2%;
    margin-right: 2rem;
    margin-left: 2rem;
  }

  .icon {
    margin: 10px;
  }

  .icon-container {
    width: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .project {
    max-width: 800px;
    font-size: 2ch;
    color: white;
  }
  
  .project svg {
    font-size: 60px;
    color: white;
  }

